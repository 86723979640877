<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col xl="9" lg="8" md="7" class="d-none d-md-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- tree -->
            <v-img class="kenburnsEffect" cover height="100%" src="@/assets/images/misc/tukas.png"></v-img>
          </div>
        </v-col>

        <v-col
          xl="3"
          lg="4"
          md="5"
          class="d-flex align-center auth-bg"
          :style="!isDark ? 'background-color:#efeef0' : null"
        >
          <v-card flat color="transparent">
            <v-img 
             :src="isDark ? textLogoDark : textLogo"
            width="250px" alt="logo" class="mb-0 mb-md-16"></v-img>

            <!-- login form -->
            <v-card-text class="px-0 mt-3">
              <v-card-title class="px-0"> Şifre Unuttum </v-card-title>
              <validation-observer ref="observer">
                <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Eski Şifre" rules="required">
                  <v-text-field
                    v-model="username"
                    filled
                    :background-color="isDark ? '#373351' : 'white'"
                    autocomplete="new-password"
                    :error-messages="errors"
                    class="mb-6 rounded-lg"
                    rounded
                    label="Kullanıcı Adınız"
                    hide-details
                  ></v-text-field>
                </validation-provider>
              </validation-observer>
              <div class="d-flex align-center justify-space-between flex-wrap">
                <!-- forget link -->

                <router-link
                  :to="{ name: 'auth-login' }"
                  class="ms-3 font-weight-medium ls-n1"
                  :class="isDark ? 'white--text' : 'primary--text'"
                >
                  Giriş Yap
                </router-link>

                <v-btn
                  rounded
                  color="success"
                  type="submit"
                  class="text-capitalize text-body-2 white--text"
                  x-large
                  :loading="loading"
                  @click="submit"
                >
                  Şifre Sıfırla
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiChevronLeft } from '@mdi/js'
import themeConfig from '@themeConfig'
import { postDataPublic } from '@utils'
import { ref } from '@vue/composition-api'
import Vue from 'vue'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const username = ref(null)
    const loading = ref(false)
    const observer = ref(null)
    const { isDark } = useAppConfig()
    const submit = async () => {
      const isValid = await observer.value.validate()
      if (isValid) {
        loading.value = true
        postDataPublic({
          method: 'memberPassReset',
          username: username.value,
        }).then(response => {
          if (response.error === 1) {
            observer.value.reset()
            username.value = null
            loading.value = false
            Vue.swal({
              title: 'Uyarı',
              text: response.msg,
              icon: 'warning',
              background: '#FFB400',
              confirmButtonColor: '#000',
            })
          } else if (response.error === false) {
            observer.value.reset()
            username.value = null
            loading.value = false
            Vue.swal({
              title: 'Başarılı',
              text: response.msg,
              icon: 'success',
              background: '#56CA00',
              confirmButtonColor: '#000',
            })
          }
        })
      }
    }

    return {
      isPasswordVisible,
      username,
      isDark,
      submit,
      loading,
      observer,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
textLogo: themeConfig.app.textLogo,
      textLogoDark: themeConfig.app.logoDark,  
      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
